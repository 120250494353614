.anime-container {
  position: relative;
}

.anime-container .dot{
  position: absolute;
  /*draw particles as circles:*/
  border-radius: 50%;
  background-color: hsl(60, 100%, 80%);
}

#dot110{
 background-color: red;
}