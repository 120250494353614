
#my-ball, #my-ball2, #my-ball3, #my-ball4, #my-ball5, #my-ball6{
  position: absolute;
  top: -100px;
  right: 300px;
  width: 50px;
  height: 50px;
  margin-top: 0px;
  margin-left: 10px;
  border-radius: 50%;
  background-color: orange;
  float: left;
}


#my-ball2{
  right: 200px;
  background-color: coral;
}


#my-ball3{
  right: 275px;
  background-color: #53c68c;
}


#my-ball4{
  right: 600px;
  background-color: red;
}


#my-ball5{
  right: 450px;
  background-color: lightblue;
}


#my-ball6{
  right: 510px;
  background-color: lightgreen;
}


#ground{
  position: absolute;
  top: 500px;
  width: 100%;
  height:100%;
  background-color: coral;
}

/* These are for the squares */
.squares-container {
  position: relative;
  background-color: green;
}

.squares-container .square{
  position: absolute;
  background-color: pink;
  width: 50px;
  height: 50px;
  border-radius: 10px;
}


.circle{
  position: absolute;
  background-color: pink;
  width: 50px;
  height: 50px;
  font-size: 30px;
  /* get it centred */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Helvetica, sans-serif;
  color: white;
}


.square{
  position: absolute;
  background-color: pink;
  width: 50px;
  height: 50px;
  font-size: 30px;
  /* get it centred */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Helvetica, sans-serif;
  color: white;
}


.square{
  border-radius: 10px;
}

.circle{
  border-radius: 50%;
}



