body {
    background-color: cadetblue;
}


/* div{
  width: 100px;
  height: 100px;
  background-color: green;
} */

#content {
  width: 50px;
  height: 50px;
  top: 70px;
  left: 70px;
  background-color: green;
  position: absolute;
}

#box {
  background-color: lightgreen;
  border: solid darkorange 3px;
  margin-left: -3px;
  margin-top: -3px;
}


#first{
  width: 100px;
  height: 100px;
  background-color: orange;
}

#r-comp{
  width: 50px;
  height: 50px;
  background-color: blue;
}


.bat-segment {
  width: 0px;
  height: 25px;
  background-color: lime;
  float: left;
}

#bat-body {
  width: 60px;
  height: 25px;
  border: solid grey 6px;
  float: left;
}

#bat-head {
  width: 5px;
  height: 15px;
  margin-top: 10px;
  background-color: grey;
  float: left;
}


.ball {
  width: 50px;
  height: 50px;
  margin-top: 100px;
  margin-left: 10px;
  border-radius: 50%;
  background-color: darkorange;
  float: left;
}


#downloader {
  margin-top: 50px;
  margin-left: 20px;
  border-radius:10px;
  opacity: 0;
}